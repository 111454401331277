.wrapper-item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-height: 89;
    background-color: #fff;
}

.wrapper-approved {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-height: 89;
    border: 2px solid #27AE60;
  }