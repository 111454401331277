/* 595 x 842 = A4 */
.mainContent {
  color: black;
  background-color: #fff;
  font-family: 'Times New Roman', Times, serif;
  font-size: 14px;
}
#bast_page {
  margin: 0 auto;
  width: 800px;
}
h1.bast_title {
  font-size: 24px;
}

.center {
  text-align: center !important;
}
#tabel-detil-po,
#tabel-detil-po th,
#tabel-detil-po td {
  border: 1px solid black;
  border-collapse: collapse;
}
#tabel-detil-po th,
#tabel-detil-po td {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

@media print {
  title,
  head,
  header,
  footer,
  aside,
  nav,
  form,
  iframe,
  .menu,
  .hero,
  .adslot {
    display: none;
  }
  body {
    background: white;

    size: 210mm 297mm;
    /* Chrome sets own margins, we change these printer settings */
    padding-top: 16mm;
    margin-bottom: 75mm;
    padding-left: 16mm;
    margin-right: 16mm;
    /* change the margins as you want them to be. */
  }
  #bast_page {
    width: auto;
  }
  h1.bast_title {
    font-size: 16px;
    margin: 0;
  }
  p,
  h5,
  table {
    font-size: 11px;
  }
}
