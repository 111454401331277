.search-checklist-text-input {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #E7E7E8;
  background-color: #F8FAFB;
  padding: 5px 8px;
  font-size: 16px;
  margin-bottom: 12px;
}
