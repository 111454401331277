.badge-count {
    padding: 5px 11px;
    background: grey;
    border-radius: 50%;
    color: #fff;
}

.zero-count-badge {
    padding: 5px 11px;
    background: white;
    border-radius: 50%;
    color: #fff;
  }

@media only screen and (max-width: 1300px) {
  .badge-count {
    padding: 2px 8px;
    background: grey;
    border-radius: 50%;
    color: #fff;
  }
}