@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://cdn.syncfusion.com/ej2/material.css');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif !important;
  background-color: #f3f5f6;
}

/* customize Date Range picker*/
.rs-picker-daterange-panel {
  background-color: #ffffff;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border: 1px solid #1b2030;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #fff;
  background-color: #1b2030;
}

.rs-picker-toolbar-right-btn-ok {
  background-color: #1b2030;
}
.rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled):hover {
  background-color: #1b2030;
}

.rs-picker-toolbar-ranges {
  display: none;
}

/* Client/Vendor */
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  max-height: 200px;
}

/* input number no spin */

.no-spin::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-spin {
  -moz-appearance:textfield !important;
}

.hyperlink-no-style:hover {
  color: inherit;
  text-decoration: inherit;
}

.bast-preview {
  font-size: 16px !important;
}

.rotate-image-up {
  transform: rotate(180deg) translateY(-100%);
  /* transform-origin:bottom; */
}
.rotate-image-down {
  transform: rotate(180deg) translateY(100%);
}

/* remark */
.custom-remark {
  width: 172px;
  height: 64px;
  font-size: 12px;
  color: red;
  font-weight: 700;
  border: 1px solid red;
  padding: 5px;
}