.MuiAutocomplete-option {
  padding: 0px 0px !important;
}

.MuiMenuItem-root {
  width: 100% !important;
  height: 100% !important;
  padding: 12px 32px !important;
}

.MuiAutocomplete-popper {
  top: 40px;
}
